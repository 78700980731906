<template>
  <header class="sombra-padrao">
    <nav id="barra-central" >
      <a href="#home">Home</a>
      <a href="#sobremim">Sobre-mim</a>
      <a href="#contato">Contato</a>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TopoSite'
}
</script>

<style scoped>

</style>
