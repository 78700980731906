<template>
  <content id="contato" class="sombra-padrao">
    <div class="container-texto">
      <h2 class="destaque">Contato</h2>
        <form>
            <div class="formulario">
            <label class="formulario-item formulario-label">Nome:</label>
            <input id="Nome" type="text" class="formulario-item" placeholder="Nome" v-model="contato.nome" required/>
            <label class="formulario-item formulario-label">Email:</label>
            <input id="Email" type="email" class="formulario-item" placeholder="contato@email.com" v-model="contato.email" required/>
            <label class="formulario-item formulario-label">Mensagem:</label>
            <textarea id="corpoEmail" class="formulario-item" rows="5" placeholder="Texto do Email" v-model="contato.corpoEmail" required></textarea>
            <button type="submit" class="formulario-item botaoSubmit" >Enviar</button>
          </div>
        </form>
      </div>
  </content>
  </template>
  
  <script>
  export default {
    name: 'ContatoSite',
    data() {
      return {
        contato:{
          nome: '',
          email: '',
          corpoEmail: ''
        }
      }
    },
    methods:{
    }
  }
  </script>
  
  <style>
  
  </style>