<template>
  <footer class="sombra-padrao">
    <i @click="irParaGithub" class="pointer icon-github">
    </i>
  </footer>
</template>

<script>
  export default {
    name: 'FooterSite',
    methods:{ 
      irParaGithub(){
        window.open("https://github.com/oleandrosantos");
      }
    }
}
</script>
