<template>
  <content id="sobremim" class="sombra-padrao">
    <div class="container-texto">
      <h2 class="destaque">Sobre mim</h2>
        <br />
        <p>Sou apaixonado por tecnologia, futebol, economia e piadas ruins.</p><br/>
        <p>
          Tenho 4 anos de experiência em atendimento ao cliente, 2 anos atuando como Assistente financeiro, 
          mas durante a pandemia eu decidi me dedicar a minha paixão, que é desenvolvimento de software e 
          desde de então tenho me dedicado a cada dia a aprender mais sobre softwares e tecnologia.
        </p><br/>
        <p>
          Me considero uma pessoa determinada, extrovertida e que ama trabalhar em equipe,
          acredito que desenvolvimento de software é sobre pessoas e devemos buscar sempre solucionar problemas da forma mais simples possível.
        </p><br/>
        <p>
          Atualmente atuo ha {{ experiencia }} como desenvolvedor de software backend, as principais stacks que tenho utilizado no meu trabalho são: C# (CSharp), .NET(DOTNET) Framework 4.6.x, ASP.NET, MySQL, Javascript e JQuery.
          Porém, tenho estudado sobre .NET CORE e VueJS.
        </p><br/>
      </div>
  </content>
  </template>
  
  <script>
  export default {
    name: 'SobreMim',
    data() {
      return {
        experiencia: 0
      }
    },
    methods:{
        obterExperiencia() {
          let hoje = new Date();
          let incioDeCarreira = new Date('02/02/2022');
          let diferenca = hoje.getTime() - incioDeCarreira.getTime();
          let meses = Math.floor(diferenca / (1000 * 60 * 60 * 24 * 30));
          if(meses > 12) {
            this.experiencia = `${Math.floor(meses / 12)} ${meses > 24 ? 'anos' : 'ano'} e ${meses % 12} ${meses % 12 > 1 ? 'meses' : 'mes'} `;
          } else {
            this.experiencia = `${meses} meses`;
          }
        }
    },
     created() {
      this.obterExperiencia()
    }
  }
  </script>
  
  <style>
  
  </style>