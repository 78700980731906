<template>
<main class="sombra-padrao">
  <div class="container-texto">
    <h1 class="texto-principal">Olá,<br/>
    Eu sou <span class="destaque">Leandro Santos</span></h1>  
    <p>Desenvolvedor back-end</p>
  </div>
  <div class="container-imagem">
    <img id="fotoPrincipal" class="img-principal" heidth="512" width="512" src="../../public/img/leandroImage.webp" alt="Imagem em preto e branco de um jovem vestido de camisa branca, com os bracos cruzados.">
  </div>
</main>
</template>

<script>
export default {
  name: 'MainSite',
  data() { },
  methods:{ }
}
</script>

<style>

</style>