<template>
  <TopoSite />
  <MainSite />
  <SobreMim />
  <ContatoSite />
  <FooterSite />
</template>

<script>
import TopoSite from './components/TopoSite.vue';
import MainSite from './components/MainSite.vue';
import SobreMim from './components/SobreMim.vue';
import ContatoSite from './components/ContatoSite.vue';
import FooterSite from './components/FooterSite.vue';

export default {
  name: 'App',
  components: {
    TopoSite,
    MainSite,
    SobreMim,
    ContatoSite,
    FooterSite
}
}
</script>

<style>

</style>
